import {Button, CustomInput, Form, FormGroup, Input, Label} from 'reactstrap';
import {useEffect, useState} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import {Element, scroller} from 'react-scroll';
import {useHistory} from "react-router-dom";
import moment from 'moment-timezone';
import apiClient from '../../services/apiClient';
import {isValidDate} from '../../utils/helpers';
import OverlayWithSpinner from '../Overlay/Overlay';
import DatePicker, {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Creatable from 'react-select/creatable';
import 'trix';
import {TrixEditor} from "react-trix";

import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'trix/dist/trix.css';
import './PostForm.scss';

registerLocale('ru', ru);

const defaultFormData = {
    "title": "",
    "image": "",
    "announce": "",
    "fulltext": "",
    "tagline": "",
    "active_from": "",
    "active_to": "",
    "is_active": 'true',
    "is_favorite": 'false',
    "sections": [],
    "tags": []
};

export default function PostForm(props) {
    const postId = props?.match?.params?.postId;

    const [formData, setFormData] = useState(defaultFormData);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [editorInstance, setEditorInstance] = useState(null);
    const [editorContent, setEditorContent] = useState('');
    const [activeFromDt, setActiveFromDt] = useState();
    const [activeToDt, setActiveToDt] = useState();
    const [errorsList, setErrorsList] = useState([]);
    const [sectionsList, setSectionsList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [overlayEnabled, setOverlayEnabled] = useState(false);
    const history = useHistory();

    const scrollToTop = () => {
        scroller.scrollTo('topElement', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    }

    const resetForm = () => {
        setFormData(defaultFormData);
    }

    async function fetchData(postId) {
        const {data: {data}} = await apiClient.get(`/posts/${postId}`);

        if (data) {
            if (data.active_from) {
                setActiveFromDt(moment(data.active_from, 'YYYY-MM-DD HH:mm:ss').toDate());
            }

            if (data.active_to) {
                setActiveToDt(moment(data.active_to, 'YYYY-MM-DD HH:mm:ss').toDate());
            }

            data.sections = data.sections.map(el => {
                return {value: el.id, label: el.name};
            });

            data.tags = data.tags.map(el => {
                return {value: el.id, label: el.name};
            });

            setFormData({
                ...data,
                image: data.image ?? '',
                tagline: data.tagline ?? '',
                is_active: data.is_active === 1 ? 'true' : 'false',
                is_favorite: data.is_favorite === 1 ? 'true' : 'false',
                active_from: data.active_from ? moment(data.active_from, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') : "",
                active_to: data.active_to ? moment(data.active_to, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') : ""
            });

            setEditorContent(data.fulltext);
        }
    }

    useEffect(() => {
        if (isFullscreen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isFullscreen]);

    const toggleFullscreen = evt => {
        evt.preventDefault();

        setIsFullscreen(!isFullscreen);
    };

    async function fetchSectionsList() {
        let {data} = await apiClient.get(`/posts/getSectionsList`);

        if (data) {
            const sectionsList = data.map(el => {
                return {value: el.id, label: el.name};
            });

            setSectionsList(sectionsList);
        }
    }

    async function fetchTagsList() {
        let {data} = await apiClient.get(`/posts/getTagsList`);

        if (data) {
            const tagsList = data.map(el => {
                return {value: el.id, label: el.name};
            });

            setTagsList(tagsList);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (postId) {
                    await fetchData(postId);
                }

                await fetchSectionsList();
                await fetchTagsList();
            } catch (error) {
                console.error('Error:', error);
            }
        })();

        return () => {
            resetForm();
        }
    }, [postId]); // eslint-disable-line

    useEffect(() => {
        if (editorInstance) {
            updateEditorContent(editorInstance, editorContent);
        }
    }, [editorContent, editorInstance]);


    const handleSectionsChange = value => {
        setFormData(data => {
            return {
                ...data,
                sections: value
            }
        });
    }

    const handleTagsChange = value => {
        setFormData(data => {
            return {
                ...data,
                tags: value
            }
        });
    };

    const handleChange = async evt => {
        const {value, name} = evt.target;

        setFormData(data => {
            return {
                ...data,
                [name]: value
            }
        });
    }

    const updateEditorContent = (editor, newContent) => {
        editor.setSelectedRange([0, editor.getDocument().toString().length]);
        editor.insertHTML(newContent);
    };

    const handleEditorReady = editor => {
        setEditorInstance(editor);
    }

    const handleEditorChange = async (html, _) => {
        setEditorContent(html);
    }

    const handleChangeActiveFromDt = async date => {
        const mdt = moment(date).format('DD.MM.YYYY HH:mm');

        setActiveFromDt(date);
        setFormData(data => {
            return {
                ...data,
                active_from: String(mdt)
            }
        })
    }

    const handleChangeActiveToDt = async date => {
        const mdt = moment(date).format('DD.MM.YYYY HH:mm');

        setActiveToDt(date);
        setFormData(data => {
            return {
                ...data,
                active_to: String(mdt)
            }
        })
    }

    const handleSaveChanges = async evt => {
        const _errorsList = [];
        let {
            active_from,
            announce,
            title,
            image,
            tagline,
            is_active,
            is_favorite,
            active_to,
            sections,
            tags
        } = formData;

        evt.stopPropagation();
        evt.preventDefault();

        let fulltext = String(editorContent);

        const sectionsList = [];
        sections?.forEach(section => {
            sectionsList.push(section.value);
        });

        const tagsList = [];
        tags?.forEach(tag => {
            tagsList.push(tag.value);
        });

        if (!title?.trim()) {
            _errorsList.push('Заполните поле "Заголовок"');
        }

        if (!fulltext?.trim()) {
            _errorsList.push('Заполните поле "Текст статьи"');
        }

        if (!active_from?.trim() || !isValidDate(active_from)) {
            _errorsList.push('Заполните поле "Действует с" в формате 01.01.2000 00:00');
        }

        if (_errorsList.length) {
            setErrorsList(_errorsList);
            scrollToTop();
            toast.error('Необходимо корректно заполнить все обязательные поля');
            return;
        } else {
            setErrorsList([]);
        }

        setOverlayEnabled(true);

        if (postId) {
            try {
                const answer = await apiClient.put(`/posts/${postId}`, {
                    title,
                    tagline,
                    image,
                    announce,
                    fulltext,
                    sectionsList,
                    tagsList,
                    active_from: moment(active_from, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm') + ':00',
                    active_to: active_to ? moment(active_to, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm') + ':00' : null,
                    is_active: Boolean(is_active === 'true'),
                    is_favorite: Boolean(is_favorite === 'true')
                });

                if (answer.status === 200 && answer.data.result === 'OK') {
                    toast.success('Данные успешно сохранены');
                    await new Promise(r => setTimeout(() => {
                        history.push(`/posts/list`)
                    }, 3200));
                } else {
                    toast.error('Произошла ошибка при сохранении данных');
                }
            } catch (err) {
                toast.error('Невозможно выполнить запрос. Повторите попытку позже');
            }

            setOverlayEnabled(false);
            return;
        }

        try {
            const answer = await apiClient.post(`/posts`, {
                title,
                tagline,
                image,
                announce,
                fulltext,
                sectionsList,
                tagsList,
                active_from: moment(active_from, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm') + ':00',
                active_to: active_to ? moment(active_to, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm') + ':00' : null,
                is_active: Boolean(is_active === 'true'),
                is_favorite: Boolean(is_favorite === 'true')
            });

            if (answer.status === 201 && answer.data.result === 'OK') {
                toast.success('Данные успешно сохранены');
                await new Promise(r => setTimeout(() => {
                    history.push(`/posts/list`)
                }, 3200));
            } else {
                toast.error('Произошла ошибка при сохранении данных');
            }
        } catch (err) {
            console.error(err);
            toast.error('Невозможно выполнить запрос. Повторите попытку позже');
        }

        setOverlayEnabled(false);
    }

    return (
        <>
            {!!errorsList.length &&
                (<ul className="errors-list">
                    {errorsList.map((errorMsg, idx) => <li key={idx}>{errorMsg}</li>)}
                </ul>)
            }
            <Element name="topperElement"/>

            <Form onSubmit={handleSaveChanges}>
                <FormGroup className="grayBorder">
                    <Label className="label" for="title">Заголовок <span className='mandatory-field'>*</span></Label>
                    <div>
                        <CustomInput
                            type="text"
                            id="title"
                            name="title"
                            style={{'width': '100%'}}
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="image">Ссылка на изображение</Label>
                    <div>
                        <CustomInput
                            type="text"
                            id="image"
                            name="image"
                            style={{'width': '100%'}}
                            value={formData.image}
                            onChange={handleChange}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="pageType">Тэглайн</Label>
                    <div>
                        <CustomInput
                            type="text"
                            id="tagline"
                            name="tagline"
                            style={{'width': '100%'}}
                            value={formData.tagline}
                            onChange={handleChange}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="announce">Анонс</Label>
                    <div>
                        <Input
                            type="textarea"
                            id="announce"
                            name="announce"
                            style={{height: '130px'}}
                            value={formData.announce ?? ''}
                            onChange={handleChange}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder" style={{'height': '500px'}}>
                    <Label className="label" for="fulltext">Текст статьи <span
                        className='mandatory-field'>*</span></Label>
                    <div style={{overflow: 'overlay', height: '450px'}}>
                        <div className={isFullscreen ? 'editor-container fullscreen' : 'editor-container'}>
                            <div className="fl-toolbar">
                                <button onClick={toggleFullscreen}>
                                    {isFullscreen ? 'Exit FS' : 'FS'}
                                </button>
                            </div>
                            <TrixEditor
                                value={editorContent}
                                onChange={handleEditorChange}
                                onEditorReady={handleEditorReady}
                                mergeTags={[]}
                            />
                        </div>
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="is_active_true">Статус публикации <span
                        className='mandatory-field'>*</span></Label>
                    <div>
                        <CustomInput
                            type="radio"
                            name="is_active"
                            id="is_active_true"
                            label="Статья опубликована"
                            inline
                            onChange={handleChange}
                            value="true"
                            checked={formData.is_active === 'true'}
                        />
                        <CustomInput
                            type="radio"
                            name="is_active"
                            id="is_active_false"
                            label="Статья отключена"
                            inline
                            onChange={handleChange}
                            value="false"
                            checked={formData.is_active === 'false'}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="is_active_true">Избранная статья <span
                        className='mandatory-field'>*</span></Label>
                    <div>
                        <CustomInput
                            type="radio"
                            name="is_favorite"
                            id="is_favorite_true"
                            label="Да"
                            inline
                            onChange={handleChange}
                            value="true"
                            checked={formData.is_favorite === 'true'}
                        />
                        <CustomInput
                            type="radio"
                            name="is_favorite"
                            id="is_favorite_false"
                            label="Нет"
                            inline
                            onChange={handleChange}
                            value="false"
                            checked={formData.is_favorite === 'false'}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="active_from">Действует с (время Саратовское) <span
                        className='mandatory-field'>*</span></Label>
                    <div>
                        <DatePicker
                            id="active_from"
                            name="active_from"
                            selected={activeFromDt}
                            dateFormat="dd.MM.yyyy HH:mm"
                            fixedHeight
                            locale={'ru'}
                            autoComplete={'off'}
                            timeIntervals={15}
                            timeFormat="p"
                            onChange={handleChangeActiveFromDt}
                            showTimeSelect
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="active_to">Действует по (время Саратовское)</Label>
                    <div>
                        <DatePicker
                            id="active_to"
                            name="active_to"
                            selected={activeToDt}
                            dateFormat="dd.MM.yyyy HH:mm"
                            fixedHeight
                            locale={'ru'}
                            autoComplete={'off'}
                            timeIntervals={15}
                            timeFormat="p"
                            onChange={handleChangeActiveToDt}
                            showTimeSelect
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="sections">
                        Разделы блога
                    </Label>
                    <div>
                        <Creatable
                            isClearable
                            isMulti
                            name="sections"
                            options={sectionsList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={formData.sections}
                            onChange={handleSectionsChange}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="grayBorder">
                    <Label className="label" for="sections">
                        Теги
                    </Label>
                    <div>
                        <Creatable
                            isClearable
                            isMulti
                            name="tags"
                            options={tagsList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleTagsChange}
                            value={formData.tags}
                        />
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="d-flex justify-content-center">
                        <Button color="info">Сохранить изменения</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button color="default" style={{'border': '1px solid gray'}}
                                onClick={() => history.push('/posts/list')}>Вернуться к списку</Button>
                    </div>
                </FormGroup>
            </Form>
            <div style={{marginTop: '15px'}}/>
            <ToastContainer autoClose={3000} closeOnClick/>
            <Element name="bottomElement"/>
            <OverlayWithSpinner
                isVisible={overlayEnabled}
            />
        </>
    )
}
