import './Home.scss';
import {useEffect} from 'react';
import {useAuth} from '../../hooks/use-auth';
import apiClient from '../../services/apiClient';
import {NavLink, useHistory} from "react-router-dom";

export const HomePage = ({pageName}) => {
    const auth = useAuth();
    const permissions = auth.getUserData()?.permissions;
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                document.title = `PediatricAdmin :: ${pageName}`;
                await apiClient.get(`/auth/ping/`);
            } catch (error) {
                console.error('Error:', error);
            }
        })();
    }, [pageName]);

    if (!permissions || !permissions.includes('can_login_admin')) {
        return (
            <>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div>
                        <h3>У вас нет разрешения на доступ к данной странице</h3>
                        <p><NavLink to="#" onClick={() => history.goBack()}>Вернуться назад</NavLink></p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow mb-12">
                        <div className="card-header py-12">
                            <div className="float-left">
                                <h6 className="m-0 font-weight-bold text-primary">{pageName}</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <p>Добро пожаловать в панель управления блогом <strong>PediatricAdmin</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop: '15px'}}/>
        </div>
    )
}
