import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import ErrorBoundary from './containers/ErrorBoundary';
import {ProvideAuth} from './hooks/use-auth';

const container = document.getElementById('react-app');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ErrorBoundary>
                <ProvideAuth>
                    <App/>
                </ProvideAuth>
            </ErrorBoundary>
        </BrowserRouter>
    </React.StrictMode>
);
