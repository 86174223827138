import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/use-auth";
import AuthService from "../../services/auth-service";
import {useHistory} from "react-router-dom";
import './Login.scss';

export const LoginPage = ({forceUpdate, pageName}) => {
    const auth = useAuth();
    const history = useHistory();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [authError, setAuthError] = useState(false);

    useEffect(() => {
        document.title = `Pediatric :: ${pageName}`;
    }, [pageName]);

    const onLoginClick = async evt => {
        evt.preventDefault();

        try {
            setAuthError('');
            const response = await AuthService.login(login, password);
            localStorage.setItem('accessToken', response.data.accessToken);
            auth.setUserData(response.data.user);
            history.push('/');
            forceUpdate();
        } catch (err) {
            console.error(err);
            setAuthError(err?.response?.data?.message);
        }
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-7 col-md-7">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Pediatric :: Вход в систему</h1>
                                        </div>
                                        {
                                            authError
                                                ?
                                                <p style={{color: 'red', fontWeight: 'bold'}}>{authError}</p>
                                                :
                                                ''
                                        }
                                        <form className="user">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="exampleInputEmail"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Логин"
                                                    defaultValue=""
                                                    onChange={evt => {
                                                        setLogin(evt.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    className="form-control form-control-user"
                                                    id="exampleInputPassword"
                                                    placeholder="Пароль"
                                                    defaultValue=""
                                                    onChange={evt => {
                                                        setPassword(evt.target.value)
                                                    }}
                                                />
                                            </div>
                                            <button
                                                className="btn btn-primary btn-user btn-block"
                                                onClick={evt => {
                                                    onLoginClick(evt)
                                                }}
                                            >
                                                Войти
                                            </button>
                                            <hr/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
