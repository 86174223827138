import React, {useMemo, useState} from 'react';
import Footer from './components/Footer/Footer';
import Sidebar from './components/Sidebar/Sidebar'
import Topbar from './components/Topbar/Topbar'
import './styles/App.scss'
import {Redirect, Route} from 'react-router-dom';
import {useAuth} from './hooks/use-auth';
import url from 'url';
import ErrorBoundary from './containers/ErrorBoundary';
import {getEnvData} from './utils';
import {useForceUpdate} from './hooks/use-force-update';
import {AddPostPage, EditPostPage, HomePage, ListPostsPage, LoginPage} from './pages';


export default function App() {
    const [appName] = useState('Pediatric');
    const parsedUrl = url.parse(window.location.href);
    const {envName} = useMemo(() => getEnvData(parsedUrl.host), [parsedUrl.host]);
    const forceUpdate = useForceUpdate();

    function PrivateRoute({pageName, pageType, PageComponent, ...rest}) {
        const auth = useAuth();

        return (
            <Route
                {...rest}
                render={props =>
                    auth?.isAuth() ? (
                        <ErrorBoundary>
                            <PageComponent
                                pageName={pageName}
                                pageType={pageType}
                                {...props}
                            />
                        </ErrorBoundary>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {from: props.location}
                            }}
                        />
                    )
                }
            />
        );
    }

    return (
        <>
            <div id="wrapper">
                <Sidebar appName={appName}/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar
                            envName={envName}
                            forceUpdate={forceUpdate}
                        />
                        <PrivateRoute
                            path="/"
                            exact
                            pageName={'Начальная страница'}
                            PageComponent={HomePage}
                        />
                        <PrivateRoute
                            path="/posts/list"
                            exact
                            pageName='Активные посты'
                            PageComponent={ListPostsPage}
                        />
                        <PrivateRoute
                            path="/posts/add"
                            exact
                            pageName={'Создание нового поста'}
                            PageComponent={AddPostPage}
                        />
                        <PrivateRoute
                            path="/posts/edit/:postId"
                            pageName={'Редактирование поста'}
                            PageComponent={EditPostPage}
                        />
                        <Route
                            path="/login"
                            exact
                        >
                            <LoginPage forceUpdate={forceUpdate} pageName={'Вход в систему'}/>
                        </Route>
                    </div>
                    <Footer/>
                </div>
            </div>
        </>
    );
}
