import './AddPost.scss';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Element} from 'react-scroll';
import PostForm from '../../components/Forms/PostForm';
import {useEffect} from 'react';
import {useAuth} from '../../hooks/use-auth';
import {NavLink, useHistory} from "react-router-dom";

export const AddPostPage = props => {
    const auth = useAuth();
    const permissions = auth.getUserData()?.permissions;
    const history = useHistory();

    useEffect(() => {
        document.title = `BlogAdmin :: ${props.pageName}`;
    }, [props.pageName]);

    if (!permissions || !permissions.includes('can_login_admin') || !permissions.includes('can_manage_posts')) {
        return (
            <>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div>
                        <h3>У вас нет разрешения на доступ к данной странице</h3>
                        <p><NavLink to="#" onClick={() => history.goBack()}>Вернуться назад</NavLink></p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="container">
            <Element name="topElement"/>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow mb-12">
                        <div className="card-header py-12">
                            <div className="float-left">
                                <h6 className="m-0 font-weight-bold text-primary">{props.pageName}</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <PostForm
                                {...props}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop: '15px'}}/>
            <ToastContainer autoClose={3000} closeOnClick/>
            <Element name="bottomElement"/>
        </div>
    );
}
