import React from "react";
import {Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {convertFromMySql} from "../../utils";

export const PostsList = ({posts, deletePost, editPost}) => {
    let listPosts = posts.map((post, idx) => {
        switch (post.is_active) {
            case 1:
                post.is_active = <span style={{'color': 'green', 'fontWeight': 'bold'}}>Опубликован</span>;
                break;

            case 0:
                post.is_active = <span style={{'color': 'red', 'fontWeight': 'bold'}}>Отключен</span>;
                break;

            default:
                break;
        }

        const postDt = convertFromMySql(post.active_from);

        return (
            <tr key={idx}>
                <td>{post.id}</td>
                <td style={{width: '330px'}}><strong>{post.title}</strong></td>
                <td>{post.tagline || '<нет>'}</td>
                <td>{postDt}</td>
                <td style={{width: '130px'}}>{post.is_active}</td>
                <td style={{width: '130px', paddingLeft: '15px', paddingRight: '15px'}}>
                    <button title="Редактировать пост" style={{'color': 'black', 'border': 'none', 'outline': 'none'}}
                            onClick={() => editPost(post.id)}><FontAwesomeIcon icon={faEdit}/></button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button title="Удалить пост" style={{'color': 'red', 'border': 'none', 'outline': 'none'}}
                            onClick={() => deletePost(post.id)}><FontAwesomeIcon icon={faTrash}/></button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </td>
            </tr>
        )
    });

    if (!listPosts.length) {
        listPosts = <tr>
            <td colSpan={7} style={{textAlign: 'center'}}>Нет данных для отображения</td>
        </tr>;
    }

    return (
        <>
            <Table striped style={{'marginTop': '15px'}} className="list-table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Заголовок</th>
                    <th>Теглайн</th>
                    <th>Дата публикации</th>
                    <th>Статус</th>
                    <th>Управление</th>
                </tr>
                </thead>
                <tbody>
                {listPosts}
                </tbody>
            </Table>
        </>);
}
