import React from 'react';
import {Spinner} from 'reactstrap';

export const WithLoading = Component => {
    return ({isLoading, ...props}) => {
        return !isLoading
            ? <Component {...props} />
            : (
                <div className="d-flex justify-content-center" style={{marginTop: '15px'}}>
                    <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '15px'}}>Загрузка данных <Spinner
                        color="primary"/></div>
                </div>
            );
    }
}
