import axios from 'axios';
import {getEnvData} from '../utils';
import url from 'url';

const parsedUrl = url.parse(window.location.href);
const {apiEndpointUrl} = getEnvData(parsedUrl.host);
export const API_URL = `${apiEndpointUrl}/admin/api/v1`;

const apiClient = axios.create({
    withCredentials: false,
    // headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    // },
    baseURL: API_URL,
    timeout: 30000
})

apiClient.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
})

apiClient.interceptors.response.use(config => {
    return config;
}, async error => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && error.config && !error.config?._isRetry) {
        originalRequest._isRetry = true;

        try {
            const response = await axios.get(`${API_URL}/auth/refresh`, {withCredentials: true});
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('userData', JSON.stringify(response.data.user));

            return apiClient.request(originalRequest);
        } catch (err) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('userData');
            window.location.href = '/login';
        }
    }

    throw error;
})

export default apiClient;
