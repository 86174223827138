import React, {useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {NavLink, useHistory} from "react-router-dom";
import apiClient from '../../services/apiClient';
import {useAuth} from '../../hooks/use-auth';
import {toast, ToastContainer} from 'react-toastify';
import {WithLoading} from '../../hoc/withLoading';
import {PostsList} from '../../components/Posts/PostsListComponent';

import './ListPosts.scss';
import 'react-toastify/dist/ReactToastify.css';

export const ListPostsPage = ({pageName, location}) => {
    const [posts, setPosts] = useState([]);
    const history = useHistory();
    const auth = useAuth();
    const permissions = auth.getUserData()?.permissions;


    async function fetchData() {
        const resData = await apiClient.get(`/posts/`);
        setPosts(resData.data);
    }

    useEffect(() => {
        (async () => {
            try {
                document.title = `Pediatric :: ${pageName}`;
                await fetchData();
            } catch (error) {
                console.error('Error:', error);
            }
        })();

    }, [pageName]);

    const deletePost = async postId => {
        if (!permissions || !permissions.includes('can_manage_posts')) {
            return toast.error('У вас нет разрешения на совершение данного действия');
        }

        if (window.confirm('Уверены, что хотите удалить пост?')) {
            await apiClient.delete(`/posts/delete/${postId}`);
            await fetchData();
            toast.info('Пост был удален');
        }
    }

    const editPost = async postId => {
        history.push(`/posts/edit/${postId}`);
    }

    if (!permissions || !permissions.includes('can_manage_posts')) {
        return (
            <>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div>
                        <h3>У вас нет разрешения на доступ к данной странице</h3>
                        <p><NavLink to="#" onClick={() => history.goBack()}>Вернуться назад</NavLink></p>
                    </div>
                </div>
            </>
        )
    }

    const PostsListWithLoading = WithLoading(PostsList);

    return (
        <>
            <div className="container list-rules-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card shadow mb-12">
                            <div className="card-header py-12">
                                <div className="float-left">
                                    <h6 className="m-0 font-weight-bold text-primary">{pageName}</h6>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        color="success"
                                        onClick={() => history.push('/posts/add')}
                                    >+ Новый пост
                                    </Button>
                                </div>
                                <PostsListWithLoading
                                    posts={posts}
                                    deletePost={deletePost}
                                    editPost={editPost}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '15px'}}/>
            </div>
            <ToastContainer autoClose={3000} closeOnClick/>
        </>
    );
}
