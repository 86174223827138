import apiClient from './apiClient';

export default class AuthService {
    static async login(login, password) {
        return apiClient.post('/auth/login', {login, password}, {withCredentials: true});
    }

    static async register(login, password, name) {
        if (name === '') {
            name = null;
        }

        return apiClient.post('/auth/register', {login, password, name});
    }

    static async logout() {
        return apiClient.get('/auth/logout');
    }

    static async refresh() {
        return apiClient.post('/auth/refresh');
    }
}
