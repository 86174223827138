import moment from 'moment-timezone';

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const getEnvData = host => {
    let apiEndpointUrl, envName;

    switch (host) {
        case 'controlcp.elenakobeleva.ru':
            apiEndpointUrl = 'https://elenakobeleva.ru';
            envName = 'PROD';
            break;

        default:
            apiEndpointUrl = 'http://localhost:8000';
            envName = 'Local';
            break;
    }

    return { apiEndpointUrl, envName };
}

export const isNumeric = str => {
    if (typeof str != "string") {
        return false;
    }

    return !isNaN(str) && !isNaN(parseFloat(str));
}

export const isString = str => {
    return typeof str === 'string' || str instanceof String;
}

export const isValidDate = dt => {
    return moment(dt, 'DD.MM.YYYY HH:mm').isValid()
}

const pgTimestampToDt = pgTimestamp => {
    return pgTimestamp.replace(/\.\d*/, '') + 'Z';
}

export const convertFromUtcPg = (datetime, tz = 'Europe/Moscow', format = 'DD.MM.YYYY HH:mm') => {
    datetime = pgTimestampToDt(datetime);
    return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ', 'Etc/UTC').tz(tz).format(format);
}

export const convertFromMySql = (datetime, format = 'DD.MM.YYYY HH:mm') => {
    return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format(format);
}

export const convertToUtcPg = (datetime, tz = 'Europe/Moscow', format = 'DD.MM.YYYY HH:mm') => {
    return moment.tz(datetime, format, tz).tz("Etc/UTC").format('YYYY-MM-DDTHH:mm:ss.000000');
}

export const enMonthToRu = str => {
    return str
        .replace('Jan', 'января')
        .replace('Feb', 'февраля')
        .replace('Mar', 'марта')
        .replace('Apr', 'апреля')
        .replace('May', 'мая')
        .replace('Jun', 'июня')
        .replace('Jul', 'июля')
        .replace('Aug', 'августа')
        .replace('Sep', 'сентября')
        .replace('Oct', 'октября')
        .replace('Nov', 'ноября')
        .replace('Dec', 'декабря');
}

export const unescaped = html => {
    return { __html: html };
}

export const nl2br = str => {
    if (str && isString(str) && str !== '') {
        return str.replaceAll("\n", '<br/>');
    }

    return str;
}

export const parseLinksInText = str => {
    if (str && isString(str) && str !== '') {
        str = str
            .replaceAll('\r\n', "\n")
            .replaceAll('\n\r', "\n")
            .split("\n")
            .map(part => {
                return part
                    .split(" ")
                    .map(part2 =>
                        URL_REGEX.test(part2) ? `<a target="_blank" href=${part2}>${part2} </a>` : part2 + " "
                    )
                    .join(" ")
            })
            .join("\n");
    }

    return str;
}