import React from 'react';

export default function OverlayWithSpinner({isVisible}) {
    return isVisible
        ?
        (
            <div className='overlay'>
                <div className='spinner'></div>
            </div>
        )
        :
        (
            <>
            </>
        )
}
