import {useState} from "react";
import {useAuth} from "../../hooks/use-auth"

export default function Topbar({envName}) {
    const auth = useAuth();
    const [menuOpened, setMenuOpened] = useState(false);

    const logout = async evt => {
        evt.preventDefault();

        if (window.confirm('Уверены, что хотите выйти?')) {
            auth.unsetUserData();
            window.location.href = '/login';
        }
    }

    return auth?.isAuth()
        ?
        (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"/>
                </button>

                <h2>ENV: [{envName}]</h2>

                <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"/>
                    <li className="nav-item dropdown no-arrow" onClick={() => setMenuOpened(!menuOpened)}>
                        {/* eslint-disable-next-line */}
                        <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <span
                                className="mr-2 d-none d-lg-inline text-gray-600 small">{auth.getUserData()?.['name'] ?? auth.getUserData()?.['login']}</span>
                            <img alt="Profile" className="img-profile rounded-circle" src="img/undraw_profile.svg"/>
                        </a>

                        <div
                            className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${menuOpened ? 'show' : ''}`}
                            aria-labelledby="userDropdown">
                            {/* eslint-disable-next-line */}
                            <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={evt => logout(evt)}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"/>
                                Выйти
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        )
        :
        (
            <>
            </>
        )
}
