import React, {createContext, useContext} from "react";

const authContext = createContext();

export function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
}

function useProvideAuth() {
    const setUserData = userData => {
        localStorage.setItem('userData', JSON.stringify(userData));
    };

    const unsetUserData = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userData');
    };

    const getUserData = () => {
        let userData = {};

        try {
            userData = JSON.parse(localStorage.getItem('userData'));
        } catch (err) {
            console.error(err);
        }

        return userData;
    };

    const isAuth = () => {
        return localStorage.getItem('userData') && localStorage.getItem('accessToken');
    };

    return {
        isAuth,
        getUserData,
        setUserData,
        unsetUserData
    };
}
