import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {NavLink} from 'react-router-dom';
import './Sidebar.scss';
import {useAuth} from '../../hooks/use-auth';

export default function Sidebar(props) {
    const auth = useAuth();

    return auth?.isAuth() ?
        (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <NavLink className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                    <div className="sidebar-brand-text mx-3" style={{display: 'inline-flex'}}>
                        <img alt="Logo" style={{height: '42px'}} src='/img/rmt-small.png'/>&nbsp;<span
                        style={{display: 'inline-block', lineHeight: '45px'}}>{props.appName}</span>
                    </div>
                </NavLink>

                <hr className="sidebar-divider my-0"/>
                <ul className="sidebar-menu">
                    <li className="nav-item">
                        <NavLink
                            to="/"
                            className="nav-link"
                            activeClassName="active"
                        >
                            <FontAwesomeIcon icon={faHome}/>&nbsp;&nbsp;
                            <span>Начальная</span>
                        </NavLink>
                    </li>
                    <hr className="sidebar-divider my-0"/>
                    <li className="nav-item">
                        {/* eslint-disable-next-line */}
                        <a className="nav-link" href="#" data-toggle="collapse" data-target="#collapseTwo"
                           aria-expanded="true" aria-controls="collapseTwo">
                            <FontAwesomeIcon icon={faPaperPlane}/>&nbsp;&nbsp;
                            <span>Посты</span>
                        </a>
                        <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo"
                             data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <NavLink
                                    to="/posts/list"
                                    className="collapse-item"
                                    activeClassName="active"
                                >
                                    Список постов
                                </NavLink>

                                <NavLink
                                    to="/posts/add"
                                    className="collapse-item"
                                    activeClassName="active"
                                >
                                    Новый пост
                                </NavLink>
                                <NavLink
                                    to="/posts/archive"
                                    className="collapse-item"
                                    activeClassName="active"
                                >
                                    Удаленные посты
                                </NavLink>
                            </div>
                        </div>
                    </li>
                    <hr className="sidebar-divider my-0"/>
                </ul>
            </ul>
        )
        :
        (
            <>
            </>
        )
}
