import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.error([error, errorInfo]);
    }

    render() {
        return this.state.hasError
            ? <><h2>В приложении произошла ошибка.</h2><h3>Пожалуйста, попробуйте перезагрузить страницу</h3></>
            : this.props.children;
    }
}
